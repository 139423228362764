<template>
  <div>
    <v-dialog
      v-model="dialog"
      persistent
      width="500"
      color="rgba(255,255,255,0"
    >
      <template v-slot:activator="{ on: dialog, attrs }">
        <v-btn
          color="white"
          elevation="2"
          dark
          v-bind="attrs"
          v-on="{ ...tooltip, ...dialog }"
        >
          <span class="primary--text"> ADD ADMINSTRATOR </span>
        </v-btn>
      </template>
      <v-stepper v-model="step">
        <v-stepper-header>
          <v-stepper-step :complete="step > 1" step="1">
            step 1
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step :complete="step > 2" step="2">
            step 2
          </v-stepper-step>
          <v-divider></v-divider>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content step="1">
            <v-form class="my-3 mx-1">
              <v-text-field
                outlined
                dense
                label="Enter Firstname"
                type="text"
                v-model="firstName"
              />
              <v-text-field
                outlined
                dense
                label="Enter Lastname"
                type="text"
                v-model="lastName"
              />
              <v-text-field
                outlined
                dense
                label="Enter username"
                type="text"
                v-model="username"
              />
              <v-text-field
                outlined
                dense
                label="Enter email"
                type="email"
                v-model="email"
              />

              <v-text-field
                v-model="password"
                outlined
                dense
                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[rules.required, rules.min]"
                :type="show ? 'text' : 'password'"
                label="Enter password"
                hint="At least 8 characters"
                @click:append="show = !show"
              />
            </v-form>
            <v-btn color="primary" @click="createUser"> Next </v-btn>

            <v-btn text @click="dialog = false"> Cancel </v-btn>
          </v-stepper-content>

          <v-stepper-content step="2">
            <v-select
              v-model="institution"
              label="Select institution"
              outlined
              :items="institutions"
              item-value="uniqueId"
              item-text="institutionName"
            />
            <v-btn color="primary" @click="registerAdminHandler">
              Register
            </v-btn>

            <v-btn text @click="dialog = false"> Cancel </v-btn>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Swal from "sweetalert2";
export default {
  name: "Button",
  data: () => ({
    dialog: false,
    username: "",
    email: "",
    password: "",
    firstName: "",
    lastName: "",
    institution: "",
    show: false,
    rules: {
      required: (value) => !!value || "Required.",
      min: (v) => v.length >= 8 || "Min 8 characters",
    },
  }),
  methods: {
    createUser() {
      if (this.firstName) {
        if (this.lastName) {
          if (this.username) {
            if (this.email) {
              if (this.password) {
                let user = {
                  firstName: this.firstName,
                  lastName: this.lastName,
                  username: this.username,
                  email: this.email,
                  password: this.password,
                };

                this.$store.dispatch("createAdminUser", user);
              } else {
                Swal.fire({
                  toast: true,
                  position: "center",
                  icon: "error",
                  title: "please provide username",
                  showConfirmButton: false,
                  timer: 1500,
                });
              }
            } else {
              Swal.fire({
                toast: true,
                position: "center",
                icon: "error",
                title: "please provide lastname",
                showConfirmButton: false,
                timer: 1500,
              });
            }
          } else {
            Swal.fire({
              toast: true,
              position: "center",
              icon: "error",
              title: "please provide username",
              showConfirmButton: false,
              timer: 1500,
            });
          }
        } else {
          Swal.fire({
            toast: true,
            position: "center",
            icon: "error",
            title: "please provide lastname",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      } else {
        Swal.fire({
          toast: true,
          position: "center",
          icon: "error",
          title: "please provide firstname",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },

    async registerAdminHandler() {
      let data = {
        institution: this.institution,
        user: parseInt(this.adminId),
      };

      await this.$store.dispatch("assignUserToInstitution", data);
      await this.$store.commit("setStep", 1);

      this.dialog = false;
      (this.firstName = ""),
        (this.lastName = ""),
        (this.username = ""),
        (this.email = ""),
        (this.password = "");
      this.$store.commit("setStep", 1);
    },
  },
  computed: {
    ...mapGetters({
      user: "getCreatedUser",

      adminId: "getCurrentRegistredAdminId",

      institutions: "getAllInstitutions",

      step: "getStep",
    }),
  },
  async mounted() {
    await this.$store.dispatch("fetchInstitutions");
    await this.$store.commit("setStep", 1);
  },
};
</script>